import './Logout.scss';

import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

import { LOCALSTORAGE } from '../../../utils/constants';

import { resetGlobalState } from '../../../store/slices/auth';

import ActivityIndicator from '../../../components/ActivityIndicator';

const Logout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        dispatch(resetGlobalState());

        localStorage.removeItem(LOCALSTORAGE.ID_TOKEN);

        // Force the browser to refresh to cancel any pending requests
        window.location.href = '/auth/login';
    }, [dispatch, navigate]);

    return (
        <>
            <Helmet>
                <title>Logging Out</title>
            </Helmet>

            <div className="auth-form">
                <div className="logout-indicator">
                    <ActivityIndicator />
                </div>
            </div>
        </>
    );
};

export default Logout;
