import { Credentials } from "../types/Credentials";
import { Profile } from "../types/Profile";

export const generateCareSession = () => {
    return {};
}

export const generateCredentials = (): Credentials => {
    return {
        email: '',
        password: ''
    }
}

export const generateProfile = (): Profile => {
    return {
        firstName: '',
        lastName: ''
    }
};
