import './Icon.scss';

import React from 'react';

type Props = {
    className?: string
    type: string //'caret-down' | 'arrow-left' | 'arrow-right' | 'avatar' | 'more' | 'plus' | 'search' | 'user' | 'x'
}

const Icon: React.FC<Props> = ({
    className = '',
    type
}) => {
    const generateClass = (type, className) => {
        let classString = `c-icon a-icon--${type}`;
        if (className != null) {
            classString += ` ${className}`;
        }
        return classString;
    };

    switch (type) {
        case 'arrow-right':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 14 14">
                    <path d="M10.4763 6.1668L6.00634 1.6968L7.18467 0.518463L13.6663 7.00013L7.18467 13.4818L6.00634 12.3035L10.4763 7.83346H0.333008V6.1668H10.4763Z" />
                </svg>
            );
        case 'x':
            return (
                <svg className={generateClass(type, className)} viewBox="0 0 9.3 9.3">
                    <path d="M3.5,4.6L0,1.1L1.1,0l3.5,3.5L8.1,0l1.2,1.2L5.8,4.6l3.5,3.5L8.1,9.3L4.6,5.8L1.1,9.3L0,8.1L3.5,4.6z"/>
                </svg>
            );
        default:
            return null;
    }
};

export default Icon;
