import './Auth.scss';

import React, { useEffect } from 'react';
import { Route, Routes } from "react-router";

import Card from '../../components/Card';
import ForgotPassword from "./ForgotPassword";
import Login from "./Login";
import Logout from "./Logout";
import ResetPassword from "./ResetPassword";

const Auth = () => {
    useEffect(() => {
        document.body.classList.add('auth-pages');

        return () => document.body.classList.remove('auth-pages');
    }, []);

    return (
        <div className="auth-page">
            <Card className="auth-page--inner">
                <div className="auth-page__logo-wrapper">
                    <img src="/img/logo-horizontal.png" alt="SuraMedical logo" />
                </div>

                <Routes>
                    <Route path="login" element={<Login />} />
                    <Route path="logout" element={<Logout />} />
                    <Route path="forgot-password" element={<ForgotPassword />} />
                    <Route path="reset-password" element={<ResetPassword />} />
                </Routes>
            </Card>
        </div>
    );
};

export default Auth;
