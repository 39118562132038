const PATHS = {
    auth: {
        forgotPassword: () => `v1/user/password/forgot`,
        getLoggedInUser: () => `v1/me`,
        login: () => `v1/user/login`,
        resetPassword: () => `v1/user/password/reset`,
    },
    careSessions: {
        active: {
            get: (page: number, pageSize: number) => `v1/care_session/active?page=${page}&page_size=${pageSize}`,
            getById: (id) => `v1/care_session/${id}/dashboard`,
        },
        inactive: {
            get: (page: number, pageSize: number) => `v1/care_session/inactive?page=${page}&page_size=${pageSize}`,
        }
    },
};

export default PATHS;
