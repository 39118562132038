import React, { useState } from 'react';
import { validated } from 'react-custom-validation';
import clone from 'clone';

import { Credentials } from "../../../types/Credentials";
import { Validation } from "../../../types/Validation";

import { handleTextChange } from '../../../utils/handle-changes';
import { isEmail, isRequired } from '../../../utils/validations';

import { useTypedSelector } from '../../../store/reducers';
import { sendForgotPasswordEmail, setCredentials } from "../../../store/slices/auth";
import { useAppDispatch } from '../../../store';

import A from '../../../components/A';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import FormError from '../../../components/FormError';
import FormRow from '../../../components/FormRow';
import Modal from '../../../components/Modal';
import Textbox from '../../../components/Textbox';

type Props = {
    credentials: Credentials
    $field: Function
    $fieldEvent: Function
    $validation: {
        email: Validation
    }
    $submit: Function
};

const ForgotPasswordForm: React.FC<Props> = ({
    credentials,
    $field,
    $fieldEvent,
    $validation,
    $submit
}) => {
    const dispatch = useAppDispatch();

    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const { isSendingForgotPasswordEmail, sendForgotPasswordEmailError } = useTypedSelector(store => store.auth);

    const handleChange = (name: string, value: string) => {
        let clonedCredentials = clone(credentials);
        clonedCredentials[name] = value;
        dispatch(setCredentials(clonedCredentials));
    };

    const handleSubmit = async (isValid: boolean) => {
        if(isValid) {
            try {
                await dispatch(sendForgotPasswordEmail()).unwrap();
                setShowSuccessModal(true);
                $fieldEvent('reset');
            } catch(err) {
                console.log('ForgotPasswordForm handleSubmit err', err);
            }
        }
    };

    return (
        <Form className="auth-form">
            <FormRow>
                <Textbox
                    id="txtEmailAddress"
                    label="Email"
                    name="email"
                    required
                    type="email"
                    validation={$validation.email}
                    value={credentials.email || ''}
                    {...$field('email', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <div className="auth-form__button-wrapper">
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false))
                    }}
                    showActivityIndicator={isSendingForgotPasswordEmail}
                    type="submit"
                >
                    Reset Password
                </Button>
            </div>

            <div className="auth-form__link-wrapper">
                <A
                    to="/auth/login"
                >
                    Back to Login
                </A>
            </div>

            <FormError
                error={sendForgotPasswordEmailError}
            />

            <Modal
                declineButtonOnClick={() => setShowSuccessModal(false)}
                declineButtonText="OK"
                show={showSuccessModal}
                title="check your email"
            >
                We've sent instructions to your email. Click the link to reset your password. Don't see it? Check your spam folder.
            </Modal>
        </Form>
    );
};

function forgotPasswordFormValidationConfig(props) {
    const { email } = props.credentials;

    return {
        fields: ['email'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ]
        }
    }
}

export default validated(forgotPasswordFormValidationConfig)(ForgotPasswordForm);
