import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import clone from "clone";

import Request from "../../utils/request";
import PATHS from "../../utils/paths";
import { LOCALSTORAGE } from "../../utils/constants";

import { addError } from "./errors";
import { RootState } from '../reducers';
import {generateCredentials, generateProfile} from "../../utils/generators";
import {IError} from "../../types/IError";
import {Credentials} from "../../types/Credentials";
import {Profile} from "../../types/Profile";

type GetLoggedInUserProps = {
    token?: string
}

export const getLoggedInUser = createAsyncThunk(
    'auth/getLoggedInUser',
    async ({token}: GetLoggedInUserProps = {}, {dispatch, getState}) => {
        if (!token) {
            token = (getState() as RootState).auth.token;
        }

        try {
            const res = await new Request(token).get(PATHS.auth.getLoggedInUser());
            const profile = res.data.data;
            return profile;
        } catch(err) {
            console.log('getLoggedInUserError', err);
            err.friendlyMessage = 'Error getting your information. Please log in again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

export const login = createAsyncThunk(
    'auth/login',
    async (_, {dispatch, getState}) => {
        try {
            const { credentials } = (getState() as RootState).auth;

            const res = await new Request().post(PATHS.auth.login(), credentials);
            console.log('login res', res);
            const profile = res.data.data;

            localStorage.setItem(LOCALSTORAGE.ID_TOKEN, profile.token);
            dispatch(setToken(profile.token));
            return profile;
        } catch(err) {
            console.log(err);
            err.friendlyMessage = 'Incorrect username or password. Please try again.';
            throw err;
        }
    }
);

type ResetPasswordProps = {
    token: string
}

export const resetPassword = createAsyncThunk(
    'auth/resetPassword',
    async ({token}: ResetPasswordProps, {dispatch, getState}) => {
        try {
            const { resetPassword1: password } = (getState() as RootState).auth.credentials;

            const res = await new Request().post(PATHS.auth.resetPassword(), {password, token});
            return res;
        } catch(err) {
            console.log(err);
            err.friendlyMessage = 'Unable to reset password. Please try again.';
            throw err;
        }
    }
);

export const sendForgotPasswordEmail = createAsyncThunk(
    'auth/sendForgotPasswordEmail',
    async (_, {dispatch, getState}) => {
        try {
            const { email } = (getState() as RootState).auth.credentials;

            const res = await new Request().post(PATHS.auth.forgotPassword(), {email});
            return res;
        } catch(err) {
            console.log(err);
            err.friendlyMessage = 'Unable to reset password. Please try again.';
            throw err;
        }
    }
);

export interface AuthState {
    credentials: Credentials
    profile: Profile
    token?: string
    isGettingLoggedInUser: boolean
    isLoggingIn: boolean
    isLoggingOut: boolean
    isResettingPassword: boolean
    isSendingForgotPasswordEmail: boolean
    getLoggedInUserError?: IError
    loginError?: IError
    loggedInUserError?: IError
    logoutError?: IError
    resetPasswordError?: IError
    sendForgotPasswordEmailError?: IError
}

const initialState: AuthState = {
    credentials: generateCredentials(),
    profile: generateProfile(),
    token: null,
    isGettingLoggedInUser: false,
    isLoggingIn: false,
    isLoggingOut: false,
    isResettingPassword: false,
    isSendingForgotPasswordEmail: false,
    getLoggedInUserError: undefined,
    loginError: undefined,
    loggedInUserError: undefined,
    logoutError: undefined,
    resetPasswordError: undefined,
    sendForgotPasswordEmailError: undefined,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        clearCredentials: (state) => {
            state.credentials = generateCredentials();
        },
        resetGlobalState: (state) => {
            state = clone(initialState);
        },
        setCredentials: (state, action) => {
            state.credentials = action.payload;
        },
        setToken: (state, action) => {
            state.token = action.payload;
        }
    },
    extraReducers: ({addCase}) => {
        addCase(getLoggedInUser.pending, (state) => {
            state.getLoggedInUserError = undefined;
            state.isGettingLoggedInUser = true;
        });
        addCase(getLoggedInUser.fulfilled, (state, action) => {
            state.profile = action.payload;
            state.isGettingLoggedInUser = false;
        });
        addCase(getLoggedInUser.rejected, (state, action) => {
            state.getLoggedInUserError = action.error as IError;
            state.isGettingLoggedInUser = false;
        });

        addCase(login.pending, (state) => {
            state.loginError = undefined;
            state.isLoggingIn = true;
        });
        addCase(login.fulfilled, (state, action) => {
            state.profile = action.payload;
            state.credentials = generateCredentials();
            state.isLoggingIn = false;
        });
        addCase(login.rejected, (state, action) => {
            state.loginError = action.error as IError;
            state.isLoggingIn = false;
        });

        addCase(resetPassword.pending, (state) => {
            state.resetPasswordError = undefined;
            state.isResettingPassword = true;
        });
        addCase(resetPassword.fulfilled, (state, action) => {
            let clonedCredentials = clone(state.credentials);
            delete clonedCredentials.resetPassword;
            delete clonedCredentials.resetPassword2;
            state.credentials = clonedCredentials;
            state.isResettingPassword = false;
        });
        addCase(resetPassword.rejected, (state, action) => {
            state.resetPasswordError = action.error as IError;
            state.isResettingPassword = false;
        });

        addCase(sendForgotPasswordEmail.pending, (state) => {
            state.sendForgotPasswordEmailError = undefined;
            state.isSendingForgotPasswordEmail = true;
        });
        addCase(sendForgotPasswordEmail.fulfilled, (state, action) => {
            state.isSendingForgotPasswordEmail = false;
        });
        addCase(sendForgotPasswordEmail.rejected, (state, action) => {
            state.sendForgotPasswordEmailError = action.error as IError;
            state.isSendingForgotPasswordEmail = false;
        });
    }
});

export const { clearCredentials, resetGlobalState, setCredentials, setToken } = authSlice.actions;

export default authSlice.reducer;
