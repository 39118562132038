import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { validated } from 'react-custom-validation';
import clone from 'clone';
import { useLocation, useNavigate } from "react-router";
import queryString from 'query-string';

import { Credentials } from "../../../types/Credentials";
import { Validation } from "../../../types/Validation";

import { handleTextChange } from '../../../utils/handle-changes';
import { isEqual, isRequired } from '../../../utils/validations';

import { resetPassword, setCredentials } from "../../../store/slices/auth";
import { useAppDispatch } from '../../../store';
import { useTypedSelector } from '../../../store/reducers';

import A from '../../../components/A';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import FormError from '../../../components/FormError';
import FormRow from '../../../components/FormRow';
import Modal from '../../../components/Modal';
import Textbox from '../../../components/Textbox';

type Props = {
    credentials: Credentials
    $field: Function
    $fieldEvent: Function
    $validation: {
        resetPassword1: Validation
        resetPassword2: Validation
    }
    $submit: Function
};

const ResetPasswordForm: React.FC<Props> = ({
    credentials,
    $field,
    $fieldEvent,
    $validation,
    $submit
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [token, setToken] = useState(null);

    const { isResettingPassword, resetPasswordError } = useTypedSelector(store => store.auth);

    useEffect(() => {
        const qs = queryString.parse(location.search);

        if(!qs.t) {
            navigate('/auth/forgot-password');
        } else {
            setToken(qs.t);
        }
    }, [dispatch, location, navigate]);

    const handleChange = (name, value) => {
        let clonedCredentials = clone(credentials);
        clonedCredentials[name] = value;
        dispatch(setCredentials(clonedCredentials));
    };

    const handleSubmit = async (isValid) => {
        if(isValid) {
            try {
                console.log(token);
                await dispatch(resetPassword({token})).unwrap();
                setShowSuccessModal(true);
                $fieldEvent('reset');
            } catch(err) {
                console.log('ResetPasswordForm handleSubmit err', err);
            }
        }
    };

    return (
        <Form className="auth-form">
            <FormRow>
                <Textbox
                    id="txtResetPassword1"
                    label="Choose a new password"
                    name="resetPassword1"
                    required
                    type="password"
                    validation={$validation.resetPassword1}
                    value={credentials.resetPassword1 || ''}
                    {...$field('resetPassword1', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtResetPassword2"
                    label="Confirm new password"
                    name="resetPassword2"
                    required
                    type="password"
                    validation={$validation.resetPassword2}
                    value={credentials.resetPassword2 || ''}
                    {...$field('resetPassword2', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <div className="auth-form__button-wrapper">
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleSubmit(true), () => handleSubmit(false))
                    }}
                    showActivityIndicator={isResettingPassword}
                    type="submit"
                >
                    Reset Password
                </Button>
            </div>

            <div className="auth-form__link-wrapper">
                <A
                    to="/auth/login"
                >
                    Back to Login
                </A>
            </div>

            <FormError
                error={resetPasswordError}
            />

            <Modal
                declineButtonOnClick={() => {
                    setShowSuccessModal(false);
                    navigate('/auth/login');
                }}
                declineButtonText="OK"
                show={showSuccessModal}
                title="Success!"
            >
                Your password has been reset. Please use it to log in.
            </Modal>
        </Form>
    );
};

function resetPasswordFormValidationConfig(props) {
    const { resetPassword1, resetPassword2 } = props.credentials;

    return {
        fields: ['resetPassword1', 'resetPassword2'],
        validations: {
            resetPassword1: [
                [isRequired, resetPassword1]
            ],
            resetPassword2: [
                [isRequired, resetPassword2],
                [isEqual, resetPassword1, resetPassword2, "Passwords must match"]
            ]
        }
    }
}

ResetPasswordForm.propTypes = {
    credentials: PropTypes.object.isRequired
};

export default validated(resetPasswordFormValidationConfig)(ResetPasswordForm);
