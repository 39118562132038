export const LOCALSTORAGE = {
    ID_TOKEN: 'id_token',
};

export const MODAL_STYLE_RESET = {
    overlay: {
        position: null,
        top: null,
        left: null,
        right: null,
        bottom: null,
        backgroundColor: null,
    },
    content: {
        position: null,
        top: null,
        left: null,
        right: null,
        bottom: null,
        border: null,
        background: null,
        overflow: null,
        WebkitOverflowScrolling: null,
        borderRadius: null,
        outline: null,
        padding: null,
    },
};

export const breakpoints = {
    largeMobileBreakpoint: '29.5em',
    smallTabletBreakpoint: '36.5em',
    maxTabletBreakpoint: '47.4375em',
    tabletBreakpoint: '47.5em',
    maxDesktopBreakpoint: '62.9375em',
    desktopBreakpoint: '63em',
    widescreenBreakpoint: '80em',
    superWideBreakpoint: '112.5em',
};
