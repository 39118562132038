import { combineReducers } from 'redux';
import { TypedUseSelectorHook, useSelector } from "react-redux";

import authReducer from './slices/auth';
import activeCareSessionsReducer from './slices/activeCareSessions';
import inactiveCareSessionsReducer from './slices/inactiveCareSessions';
import errorsReducer from './slices/errors';

const rootReducer = combineReducers({
    auth: authReducer,
    activeCareSessions: activeCareSessionsReducer,
    inactiveCareSessions: inactiveCareSessionsReducer,
    errors: errorsReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;

export const useTypedSelector: TypedUseSelectorHook<RootState> = useSelector;
