import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Request from "../../utils/request";
import PATHS from "../../utils/paths";

import { addError } from "./errors";
import { RootState } from '../reducers';
import {generateCareSession} from "../../utils/generators";
import {IError} from "../../types/IError";
import {CareSession} from "../../types/CareSession";

type GetActiveCareSessionsProps = {
    page?: number
    pageSize?: number
}

export const getActiveCareSessions = createAsyncThunk(
    'auth/getActiveCareSessions',
    async ({page, pageSize}: GetActiveCareSessionsProps, {dispatch, getState}) => {

        const searchPage = page ? page : 0;
        const searchPageSize = pageSize ? pageSize : 0;

        try {
            const res = await new Request((getState() as RootState).auth.token).get(PATHS.careSessions.active.get(searchPage, searchPageSize));
            console.log(res);
            const careSessions = res.data.data.items;
            return careSessions;
        } catch(err) {
            console.log('getCareSessions', err);
            err.friendlyMessage = 'Error getting care sessions. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

type GetActiveCareSessionProps = {
    id: number | string
}

export const getActiveCareSession = createAsyncThunk(
    'auth/getActiveCareSession',
    async ({id}: GetActiveCareSessionProps, {dispatch, getState}) => {
        try {
            const res = await new Request((getState() as RootState).auth.token).get(PATHS.careSessions.active.getById(id));
            console.log(res);
            const careSession = res.data.data;
            careSession.walkingChartValues.convertedY = [];
            careSession.legUpChartValues.convertedY = [];
            careSession.legDownChartValues.convertedY = [];
            careSession.numPushesChartValues.convertedY = [];
            careSession.dataCompletenessChartValues.convertedY = [];

            careSession.walkingChartValues.y.forEach((y, i) => {
                let value = (y / 60);
                careSession.walkingChartValues.convertedY.push(value);
            });
            careSession.legUpChartValues.y.forEach((y, i) => {
                let value = (y / 60);
                if(value > 24) {
                    value = 24;
                }
                careSession.legUpChartValues.convertedY.push(value);
            });
            careSession.legDownChartValues.y.forEach((y, i) => {
                let value = (y / 60);
                careSession.legDownChartValues.convertedY.push(value);
            });
            careSession.numPushesChartValues.y.forEach((y, i) => {
                careSession.numPushesChartValues.convertedY.push(y);
            });
            careSession.dataCompletenessChartValues.y.forEach((y, i) => {
                careSession.dataCompletenessChartValues.convertedY.push(y);
            });
            return careSession;
        } catch(err) {
            console.log('getCareSession', err);
            err.friendlyMessage = 'Error getting that care session. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

export interface ActiveCareSessionsState {
    session: CareSession
    sessionData: any
    activeSessions: Array<CareSession>
    isGettingCareSession: boolean
    isGettingActiveCareSessions: boolean
    getCareSessionError?: IError
    getActiveCareSessionsError?: IError
}

const initialState: ActiveCareSessionsState = {
    session: generateCareSession(),
    sessionData: {},
    activeSessions: [],
    isGettingCareSession: false,
    isGettingActiveCareSessions: false,
    getCareSessionError: undefined,
    getActiveCareSessionsError: undefined,
};

export const activeCareSessionsSlice = createSlice({
    name: 'activeCareSessions',
    initialState,
    reducers: {
        clearCredentials: (state) => {
            state.session = generateCareSession();
        },
        setCareSession: (state, action) => {
            state.session = action.payload;
        },
    },
    extraReducers: ({addCase}) => {
        addCase(getActiveCareSession.pending, (state) => {
            state.getCareSessionError = undefined;
            state.isGettingCareSession = true;
        });
        addCase(getActiveCareSession.fulfilled, (state, action) => {
            state.sessionData = action.payload;
            state.isGettingCareSession = false;
        });
        addCase(getActiveCareSession.rejected, (state, action) => {
            state.getCareSessionError = action.error as IError;
            state.isGettingCareSession = false;
        });

        addCase(getActiveCareSessions.pending, (state) => {
            state.getActiveCareSessionsError = undefined;
            state.isGettingActiveCareSessions = true;
        });
        addCase(getActiveCareSessions.fulfilled, (state, action) => {
            state.activeSessions = action.payload;
            state.isGettingActiveCareSessions= false;
        });
        addCase(getActiveCareSessions.rejected, (state, action) => {
            state.getActiveCareSessionsError = action.error as IError;
            state.isGettingCareSession = false;
        });
    }
});

export const { clearCredentials, setCareSession } = activeCareSessionsSlice.actions;

export default activeCareSessionsSlice.reducer;
