import validator from 'validator';
import moment from 'moment';

export const isEmail = (email?: string, errorMessage?: string) => {
    return email != null && validator.isEmail(email) ? null : errorMessage || `Please enter a valid email.`;
};

export const isEmailIfTrue = (value?: string, booleanValue?: boolean, errorMessage?: string) => {
    if (booleanValue === true) {
        return isEmail(value, errorMessage);
    } else {
        return null;
    }
};

export const isRequired = (value?: string, errorMessage?: string) => {
    if (Array.isArray(value) === true && value.length === 0) {
        return errorMessage || 'This field is required.';
    }
    return value != null && value !== '' ? null : errorMessage || 'This field is required.';
};

export const isRequiredObject = (value?: Array<any> | any, errorMessage?: string) => {
    if (Array.isArray(value) === true && value.length === 0) {
        return errorMessage || 'This field is required.';
    }
    return value != null && value !== '' && Object.keys(value).length > 0 ? null : errorMessage || 'This field is required.';
};

export const isRequiredIfTrue = (value?: string, booleanValue?: boolean, errorMessage?: string) => {
    if (booleanValue === true) {
        if (Array.isArray(value) === true && value.length === 0) {
            return errorMessage || 'This field is required.';
        }

        return value != null && value !== '' ? null : errorMessage || 'This field is required.';
    } else {
        return null;
    }
};

export const isRequiredIfOther = (value?: string | object, valueOfSelect?: string) => {
    if (valueOfSelect != null && valueOfSelect.toLowerCase() === 'other') {
        return validator.isEmpty(value) === false ? null : 'This field is required.';
    } else {
        return true;
    }
};

export const isRequiredIfSiblingNotNull = (value?: string | object, valueOfSibling?: any, nameOfSibling?: string) => {
    if (valueOfSibling != null) {
        return validator.isEmpty(value) === false ? null : `This field is required when ${nameOfSibling} has a value.`;
    } else {
        return null;
    }
};

export const isPhoneNumber = (value?: string) => {
    let onlyNumbers = '';
    if (value != null) {
        onlyNumbers = value.replace(/\D/g, '');
    }
    return value != null && (onlyNumbers.length === 9 || onlyNumbers.length === 10)
        ? null
        : `Please enter a valid phone number`;
};

export const isDate = (value?: string) => {
    return validator.isDate(new Date(value).toString()) ? null : `Please enter a valid date.`;
};

export const isDateInFuture = (value?: string) => {
    return moment(value).isSameOrAfter(moment(), 'day') ? null : 'Please choose a date that is in the future.';
};

export const isDateAfterOtherDate = (value?: string, otherDate?: string, errorMessage?: string) => {
    return value == null || value === '' || moment(value).valueOf() > moment(otherDate).valueOf() ? null : errorMessage || 'Please choose a date later than the start date.';
}

export const isTrue = (value?: boolean, message?: string) => {
    return value === true ? null : message;
};

export const isEqual = (value1?: string | number | boolean, value2?: string | number | boolean, message?: string) => {
    return value1 === value2 ? null : message;
};

export const isGreaterThanOrEqualTo = (value1?: string | number, value2?: string | number, message?: string) => {
    return value1 >= value2 ? null : message;
};

export const isLessThan = (value1?: string | number, value2?: string | number, message?: string) => {
    return value1 < value2 ? null : message;
};

export const isStringShorterThan = (value?: string, maxLength?: number, errorMessage?: string) => {
    return value == null || value === '' || value.length <= maxLength
        ? null
        : errorMessage || `Shorten to ${maxLength} characters.`;
};

export const isStringLongerThan = (value?: string, minLength?: number, errorMessage?: string) => {
    return value == null || value === '' || value.length >= minLength
        ? null
        : errorMessage || `Lengthen to ${minLength} characters.`;
};

export const isStringNLong = (value?: string, lengthsArray?: Array<number>, errorMessage?: string) => {
    if (value == null || value === '') {
        return null;
    } else {
        let matches = false;

        lengthsArray.forEach(length => {
            if (value.length === length) {
                matches = true;
            }
        });

        return matches ? null : errorMessage || 'Match required length';
    }
};
