import { createSlice } from '@reduxjs/toolkit';
import clone from 'clone';
import { IError } from "../../types/IError";

interface ErrorsState {
    errors: Array<IError>
}

export const initialState = {
    errors: []
};

export const errorsSlice = createSlice({
    name: 'errors',
    initialState,
    reducers: {
        addError: (state, action) => {
            let errors = clone(state.errors);
            const error = action.payload;

            for(var i = 0; i < errors.length; i++) {
                if(errors[i].friendlyMessage === error.friendlyMessage) {
                    return;
                }
            }

            errors = errors.concat([error]);
            if(errors.length > 5) {
                errors = errors.slice(1, 5);
            }

            state.errors = errors;
        },
        clearErrors: (state) => {
            state.errors = [];
        },
        setErrors: (state, action) => {
            state.errors = action.payload;
        },
    }
});

export const { addError, clearErrors, setErrors } = errorsSlice.actions;

export default errorsSlice.reducer;
