import './Dashboard.scss';

import React, {useEffect, useState} from 'react';
import {getActiveCareSessions, setCareSession} from '../../store/slices/activeCareSessions';
import {getInactiveCareSessions} from '../../store/slices/inactiveCareSessions';
import moment from 'moment';
import {useAppDispatch} from "../../store";
import {useTypedSelector} from "../../store/reducers";

import A from "../../components/A";
import ActivityIndicator from "../../components/ActivityIndicator";
import Card from '../../components/Card';
import Button from '../../components/Button';
import {CareSession} from "../../types/CareSession";
import H4 from "../../components/H4";
import H5 from '../../components/H5';
import Icon from "../../components/Icon";

type Props = {

}

enum PaginationButtonType {
    Next = 'n',
    Previous = 'p'
}

const Dashboard: React.FC<Props> = ({}) => {
    const dispatch = useAppDispatch();

    const { isGettingActiveCareSessions, activeSessions } = useTypedSelector((state) => state.activeCareSessions);
    const { isGettingInactiveCareSessions, inactiveSessions } = useTypedSelector((state) => state.inactiveCareSessions);

    const [activePage, setActivePage] = useState(0)
    const [activePageSize, setActivePageSize] = useState(10)

    const [inactivePage, setInactivePage] = useState(0)
    const [inactivePageSize, setInactivePageSize] = useState(10)

    const {token} = useTypedSelector((state) => state.auth);

    useEffect(() => {
        const initialize = async () => {
            try {
                await Promise.all([
                    dispatch(getActiveCareSessions({page: activePage, pageSize: activePageSize})).unwrap(),
                    dispatch(getInactiveCareSessions({page: inactivePage, pageSize: inactivePageSize})).unwrap()
                ])

            } catch(err) {
                console.log('Dashboard initialize err', err);
            }
        };

        initialize();
    }, []);

    const handleActivePaginationClick = async (type: PaginationButtonType) => {
        switch (type) {
            case PaginationButtonType.Next:
                const nextPage = inactivePage + 1;
                setActivePage(nextPage)
                try {
                    await dispatch(getActiveCareSessions({page: nextPage, pageSize: activePageSize})).unwrap();
                } catch(err) {
                    console.log('Dashboard initialize err', err);
                }
                break;

            case PaginationButtonType.Previous:
                let previousPage: number

                if (inactivePage > 0) {
                    previousPage = inactivePage - 1;
                }
                else if (inactivePage === 0) {
                    previousPage = 0;
                }
                setActivePage(previousPage);
                try {
                    await dispatch(getActiveCareSessions({page: previousPage, pageSize: inactivePageSize})).unwrap();
                } catch(err) {
                    console.log('Dashboard initialize err', err);
                }
                break;
            default:
                console.log("Incorrect pagination button type");
                break;
        }
    }

    const handleInactivePaginationClick = async (type: PaginationButtonType) => {
        switch (type) {
            case PaginationButtonType.Next:
                const nextPage = inactivePage + 1;
                setInactivePage(nextPage)
                try {
                    await dispatch(getInactiveCareSessions({page: nextPage, pageSize: inactivePageSize})).unwrap();
                } catch(err) {
                    console.log('Dashboard initialize err', err);
                }
                break;

            case PaginationButtonType.Previous:
                let previousPage: number
                if (inactivePage > 0) {
                    previousPage = inactivePage - 1;
                }
                else if (inactivePage === 0) {
                    previousPage = 0;
                }
                setInactivePage(previousPage);
                try {
                    await dispatch(getInactiveCareSessions({page: previousPage, pageSize: inactivePageSize})).unwrap();
                } catch(err) {
                    console.log('Dashboard initialize err', err);
                }
                break;
            default:
                console.log("Incorrect pagination button type");
                break;
        }
    }

    const formatDate = (date: Date) => {
        return moment(date).format('ddd MMM D YYYY, h:mm a');
    }

    return (
        <div className="page-content">
            <div>
                <H4>
                    Active Care Sessions
                </H4>


                <Card className="care-sessions-list">
                {isGettingActiveCareSessions && <ActivityIndicator />}
                    <H5 className='care-session__pagination-page'>
                        {`Page: ${activePage + 1}`}
                    </H5>
                    <div className="care-session-link">
                        <div className="care-session-link__id">
                            <strong>Tag ID</strong>
                        </div>

                        <div className="care-session-link__id">
                            <strong>Started</strong>
                        </div>

                        <div className="care-session-link__id">
                            <strong>Battery</strong>
                        </div>

                        <div className="care-session-link__arrow">

                        </div>
                    </div>
                    {activeSessions.map((session: CareSession) => (
                        <A onClick={() => dispatch(setCareSession(session))} to={`/session/${session.id}`}>
                            <div className="care-session-link">
                                <div className="care-session-link__id">
                                    <strong>{session.currentClinicVisit?.sensorTag?.macAddress}</strong>
                                </div>

                                <div className="care-session-link__id">
                                    <strong>{formatDate(session.currentClinicVisit?.startAt)}</strong>
                                </div>

                                <div className="care-session-link__id">
                                    {session.lowBattery ? (
                                        <strong>LOW</strong>
                                    ) : (
                                        <p>ok</p>
                                    )}
                                </div>

                                <div className="care-session-link__arrow">
                                    <Icon type="arrow-right" />
                                </div>
                            </div>
                        </A>
                    ))}

                    <div className="care-session__pagination-options">
                        {activePage !== 0 && (
                            <Button className='care-session__pagination-button'
                                    onClick={() => handleActivePaginationClick( PaginationButtonType.Previous)}
                            >
                                Previous
                            </Button>
                        )}
                        {activePage === activeSessions.length && (
                            <Button className='care-session__pagination-button'
                                    onClick={() => handleActivePaginationClick(PaginationButtonType.Next)}
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </Card>

                <H4>
                    Inactive Care Sessions
                </H4>


                <Card className="care-sessions-list">
                {isGettingInactiveCareSessions && <ActivityIndicator />}
                    <H5 className='care-session__pagination-page'>
                        {`Page: ${inactivePage + 1}`}
                    </H5>
                    <div>
                        <div className="care-session-link">
                            <div className="care-session-link__id">
                                <strong>Tag ID</strong>
                            </div>

                            <div className="care-session-link__id">
                                <strong>Started</strong>
                            </div>

                            <div className="care-session-link__id">
                                <strong>Ended</strong>
                            </div>

                            <div className="care-session-link__id">
                                <strong>Download</strong>
                            </div>
                        </div>
                    </div>
                    {inactiveSessions.map((session: CareSession) => (
                        <div>
                            <div className="care-session-link">
                                <div className="care-session__inactive-row">
                                    {session.currentClinicVisit?.sensorTag?.macAddress}
                                </div>

                                <div className="care-session__inactive-row">
                                    {formatDate(session.currentClinicVisit?.startAt)}
                                </div>

                                <div className="care-session__inactive-row">
                                    {formatDate(session.currentClinicVisit?.endAt)}
                                </div>

                                <div>
                                    <Button href={process.env.REACT_APP_API_URL + `v1/export/chart_data?care_session_id=${session.id}&t=${token}`}>
                                        Charts CSV
                                    </Button>
                                    <Button href={process.env.REACT_APP_API_URL + `v1/export/packets?care_session_id=${session.id}&t=${token}`}>
                                        Raw CSV
                                    </Button>
                                    <Button href={process.env.REACT_APP_API_URL + `v1/export/pressure?care_session_id=${session.id}&t=${token}`}>
                                        Pressure CSV
                                    </Button>
                                </div>
                            </div>
                        </div>
                    ))}
                    <div className="care-session__pagination-options">
                        {inactivePage !== 0 && (
                            <Button className='care-session__pagination-button'
                                    onClick={() => handleInactivePaginationClick(PaginationButtonType.Previous)}
                            >
                                Previous
                            </Button>
                        )}
                        {inactivePageSize === inactiveSessions.length && (
                            <Button className='care-session__pagination-button'
                                    onClick={() => handleInactivePaginationClick(PaginationButtonType.Next)}
                            >
                                Next
                            </Button>
                        )}
                    </div>
                </Card>
            </div>
        </div>
    );
};

export default Dashboard;
