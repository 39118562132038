import React from 'react';
import { validated } from 'react-custom-validation';
import clone from 'clone';
import { useNavigate } from "react-router-dom";

import { Credentials } from "../../../types/Credentials";
import { Validation } from "../../../types/Validation";

import { handleTextChange } from '../../../utils/handle-changes';
import { isEmail, isRequired } from '../../../utils/validations';

import { login, setCredentials } from "../../../store/slices/auth";
import { useTypedSelector } from '../../../store/reducers';
import { useAppDispatch } from '../../../store';

import A from '../../../components/A';
import Button from '../../../components/Button';
import Form from '../../../components/Form';
import FormError from '../../../components/FormError';
import FormRow from '../../../components/FormRow';
import Textbox from '../../../components/Textbox';

type Props = {
    credentials: Credentials
    $field: Function
    $fieldEvent: Function
    $validation: {
        email: Validation
        password: Validation
    }
    $submit: Function
};

const LoginForm: React.FC<Props> = ({
    credentials,
    $field,
    $fieldEvent,
    $validation,
    $submit
}) => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { isLoggingIn, loginError } = useTypedSelector(store => store.auth);

    const handleChange = (name, value) => {
        let clonedCredentials = clone(credentials);
        clonedCredentials[name] = value;
        dispatch(setCredentials(clonedCredentials));
    };

    const handleLogin = async (isValid) => {
        if(isValid) {
            try {
                let res = await dispatch(login()).unwrap();
                $fieldEvent('reset');

                navigate('/dashboard');
            } catch(err) {
                console.log('LoginForm handleLogin err', err);
            }
        }
    };

    return (
        <Form className="auth-form">
            <FormRow>
                <Textbox
                    id="txtEmailAddress"
                    label="Email"
                    name="email"
                    required
                    type="email"
                    validation={$validation.email}
                    value={credentials.email || ''}
                    {...$field('email', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormRow>
                <Textbox
                    id="txtPassword"
                    label="Password"
                    name="password"
                    required
                    type="password"
                    validation={$validation.password}
                    value={credentials.password || ''}
                    {...$field('password', event => handleTextChange(handleChange, event))}
                />
            </FormRow>

            <FormError
                error={loginError}
            />

            <div className="auth-form__button-wrapper">
                <Button
                    onClick={(event) => {
                        event.preventDefault();
                        $submit(() => handleLogin(true), () => handleLogin(false))
                    }}
                    showActivityIndicator={isLoggingIn}
                    type="submit"
                >
                    Log In
                </Button>
            </div>

            <div className="auth-form__link-wrapper">
                <A
                    to="/auth/forgot-password"
                >
                    Forgot Your Password?
                </A>
            </div>
        </Form>
    );
};

function loginFormValidationConfig(props) {
    const { email, password } = props.credentials;

    return {
        fields: ['email', 'password'],
        validations: {
            email: [
                [isRequired, email],
                [isEmail, email]
            ],
            password: [
                [isRequired, password]
            ]
        }
    }
}

export default validated(loginFormValidationConfig)(LoginForm);
