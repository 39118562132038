import './MainHeader.scss';

import React from 'react';

type Props = {

}

const MainHeader: React.FC<Props> = ({}) => {
    return (
        <header className="c-main-header">
            <img src="/img/logo-horizontal.png" alt="SuraMedical logo" />
        </header>
    );
};

export default MainHeader;
