import './Login.scss';

import React from 'react';
import { Helmet } from 'react-helmet';

import { useTypedSelector } from '../../../store/reducers';

import LoginForm from "./LoginForm";

const Login = () => {
    const { credentials } = useTypedSelector(store => store.auth);

    return (
        <>
            <Helmet>
                <title>Log In</title>
            </Helmet>

            <LoginForm
                credentials={credentials}
            />
        </>
    );
};

export default Login;
