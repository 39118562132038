import './Textbox.scss';

import React from 'react';
import classNames from 'classnames';

import { Validation } from "../../types/Validation";

import FormValidationMessage from '../FormValidationMessage';
import Label from '../Label';
import Icon from "../Icon";

type Props = {
    className?: string
    disabled?: boolean
    icon?: string
    id: string
    thisRef?: React.Ref<HTMLInputElement> | React.Ref<HTMLTextAreaElement> | any
    instructions?: string
    label?: string | object
    name?: string
    onChange: Function
    placeholder?: string
    required?: boolean
    size?: 'small'
    type?: 'text' | 'textarea' | 'number' | 'email' | 'password'
    validation?: Validation
    value?: string
}

const Textbox: React.FC<Props> = ({
    className,
    icon,
    instructions,
    label,
    onChange,
    required,
    size,
    thisRef,
    type,
    validation,
    value,
    ...otherProps
                                  }) => {
    let classes = classNames(
        'o-textbox',
        className,
        {
            'has-error': validation?.show === true && validation?.isValid === false,
            'has-icon': icon != null,
            'a-textbox--disabled': otherProps.disabled === true,
            'a-textbox--small': size === 'small'
        }
    );

    if(required && otherProps.placeholder) {
        otherProps.placeholder = otherProps.placeholder + '*';
    }

    return (
        <div className={classes}>
            {label != null ? (
                <Label htmlFor={otherProps.id}>
                    {label} {required === true ? <span className="c-label__required-asterisk">*</span> : ''}
                </Label>
            ) : null}

            <div className="c-textbox--wrapper">
                {icon != null && (
                    <Icon type={icon} />
                )}

                {type === 'textarea' ? (
                    <textarea
                        ref={thisRef}
                        className="c-textbox a-textbox--textarea"
                        onChange={(event) => onChange(event)}
                        rows={5}
                        value={value}
                        {...otherProps}
                    />
                ) : (
                    <input
                        ref={thisRef}
                        className="c-textbox"
                        onChange={(event) => onChange(event)}
                        type={type || 'text'}
                        value={value}
                        {...otherProps}
                    />
                )}
            </div>

            {instructions ? (
                <div className="c-textbox__instructions">
                    {instructions}
                </div>
            ) : null}

            {validation && validation.show ? <FormValidationMessage>{validation.error.reason}</FormValidationMessage> : null}
        </div>
    );
};

export default Textbox;
