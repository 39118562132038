import './App.scss';

import React, {useEffect, useState} from 'react';
import { Route, Routes } from "react-router";
import { isMobile } from 'react-device-detect';
import { useLocation , useNavigate} from "react-router-dom";

import { isAuthPage } from "../../utils/utils";
import { LOCALSTORAGE } from "../../utils/constants";

import { getLoggedInUser, setToken } from "../../store/slices/auth";
import { useAppDispatch } from "../../store";
import { useTypedSelector } from "../../store/reducers";

import Auth from "../Auth";
import Dashboard from "../Dashboard";
import ActivityIndicator from "../../components/ActivityIndicator";
import MainHeader from "../../components/MainHeader";
import ViewSession from "../ViewSession";

const App: React.FC = () => {
    const dispatch = useAppDispatch();
    const location = useLocation();
    const navigate = useNavigate();

    const [hasFetchedProfile, setHasFetchedProfile] = useState(false);

    const { isGettingLoggedInUser } = useTypedSelector((state) => state.auth);

    useEffect(() => {
        const initialize = async () => {
            const authToken = localStorage.getItem(LOCALSTORAGE.ID_TOKEN);
            const { pathname } = window.location;

            if(isMobile) {
                document.body.classList.add('is-mobile');
            }

            setTimeout(() => document.body.classList.add('loaded'), 200);

            console.log('authToken', authToken)

            if (authToken == null) {
                if(!isAuthPage(pathname)) {
                    navigate('/auth/login');
                }
                setHasFetchedProfile(true);
            } else {
                try {
                    let res: any = await dispatch(getLoggedInUser({token: authToken})).unwrap();
                    dispatch(setToken(authToken));

                    if(isAuthPage(location.pathname) || location.pathname === '/') {
                        navigate('/dashboard');
                    }

                    setHasFetchedProfile(true);
                } catch {
                    navigate('/auth/logout');
                    setHasFetchedProfile(true);
                }
            }
        };

        initialize();
    }, [dispatch, navigate]);

    return (
        <>
            {isGettingLoggedInUser || !hasFetchedProfile ? (
                <ActivityIndicator />
            ) : (
                <>
                    <Routes>
                        <Route path="/dashboard" element={<MainHeader />} />
                        <Route path="/session/:id" element={<MainHeader />} />
                    </Routes>

                    <Routes>
                        <Route path="/auth/*" element={<Auth />} />
                        <Route path="/dashboard" element={<Dashboard />} />
                        <Route path="/session/:id" element={<ViewSession />} />
                    </Routes>
                </>
            )}
        </>
    );
};

export default App;
