import './ActivityIndicator.scss';

import React from 'react';

type Props = {
    color?: string,
    size?: 'large'
}

const ActivityIndicator: React.FC<Props> = ({
    color,
    size = '',
}) => (
    <div className={`c-activity-indicator c-activity-indicator--${size}`}>
        <div className="rect1"></div>
        <div className="rect2"></div>
        <div className="rect3"></div>
        <div className="rect4"></div>
        <div className="rect5"></div>
    </div>
);

export default ActivityIndicator;
