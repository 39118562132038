import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import Request from "../../utils/request";
import PATHS from "../../utils/paths";

import { addError } from "./errors";
import { RootState } from '../reducers';
import {IError} from "../../types/IError";
import {CareSession} from "../../types/CareSession";


type GetInactiveCareSessionsProps = {
    page?: number,
    pageSize?: number
}

export const getInactiveCareSessions = createAsyncThunk(
    'auth/getInactiveCareSessions',
    async ({page, pageSize}: GetInactiveCareSessionsProps, {dispatch, getState}) => {
        const searchPage = page ? page : 0
        const searchPageSize = pageSize ? pageSize : 20


        try {
            const res = await new Request((getState() as RootState).auth.token).get(PATHS.careSessions.inactive.get(searchPage, searchPageSize));
            console.log(res);
            const careSessions = res.data.data.items;
            return careSessions;
        } catch(err) {
            console.log('getCareSessions', err);
            err.friendlyMessage = 'Error getting care sessions. Please try again.';
            dispatch(addError(err));
            throw err;
        }
    }
);

export interface InactiveCareSessionsState {
    inactiveSessions: Array<CareSession>
    isGettingInactiveCareSessions: boolean
    getInactiveCareSessionsError?: IError
}

const initialState: InactiveCareSessionsState = {
    inactiveSessions: [],
    isGettingInactiveCareSessions: false,
    getInactiveCareSessionsError: undefined,
};

export const inactiveCareSessionsSlice = createSlice({
    name: 'inactiveCareSessions',
    initialState,
    reducers: {},
    extraReducers: ({addCase}) => {
        addCase(getInactiveCareSessions.pending, (state) => {
            state.getInactiveCareSessionsError = undefined;
            state.isGettingInactiveCareSessions = true;
        });
        addCase(getInactiveCareSessions.fulfilled, (state, action) => {
            state.inactiveSessions = action.payload;
            state.isGettingInactiveCareSessions = false;
        });
        addCase(getInactiveCareSessions.rejected, (state, action) => {
            state.getInactiveCareSessionsError = action.error as IError;
            state.isGettingInactiveCareSessions = false;
        });
    }
});

export default inactiveCareSessionsSlice.reducer;
