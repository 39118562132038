import './Button.scss';

import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import ActivityIndicator from "../ActivityIndicator";

type Props = {
    children: React.ReactNode
    className?: string
    disabled?: boolean
    href?: string
    onClick?: Function
    noStyles?: boolean
    showActivityIndicator?: boolean
    size?: 'small'
    state?: object
    target?: string
    theme?: 'primary' | 'secondary'
    to?: string
    type?: 'button' | 'reset' | 'submit'
};

const Button: React.FC<Props> = ({
    children,
    className = '',
    disabled,
    href,
    noStyles,
    onClick,
    showActivityIndicator,
    size,
    state,
    target,
    theme,
    to,
    type = 'button',
}) => {
    let classes = classNames(
        'c-button',
        size != null && `a-button--${size}`,
        theme != null ? `a-button--${theme}` : 'a-button--primary',
        className,
        {
            'a-button--no-styles': noStyles === true,
            'a-button--disabled': (href != null || to != null) && (disabled === true || showActivityIndicator === true)
        });
    if(href != null) {
        return (
            <a
                className={classes}
                href={href}
                target={target}
            >
                {children}
            </a>
        );
    } else if(to != null) {
        return (
            <Link
                className={classes}
                state={state}
                to={to}
            >
                {children}
            </Link>
        )
    } else {
        return (
            <button
                className={classes}
                disabled={disabled || showActivityIndicator}
                // @ts-ignore
                onClick={(e) => onClick && onClick(e)}
                type={type}
            >
                {children}

                {showActivityIndicator ? (
                    <div className="c-button__activity-indicator">
                        <ActivityIndicator />
                    </div>
                ) : null}
            </button>
        );
    }
};

export default Button;
