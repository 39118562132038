export const formatAsDollarAmount = (number?: number) => {
    if(number == null) {
        return null;
    }

    return new Intl.NumberFormat('en-US', {style: 'currency', currency: 'USD'}).format(number);
};

export const isAuthPage = (location: string) => {
    return location.includes('/auth/');
};

export const isArrayNullOrEmpty = (arr?: Array<any>) => {
    return arr == null || arr.length === 0;
};

export const isObjectNullOrEmpty = (obj?: object) => {
    return obj == null || Object.keys(obj).length === 0;
};
